import React, { useEffect } from "react"
import Layout from "../components/Layout"

export default function Marina() {
  useEffect(() => {
    // const script = document.createElement("script")
    // script.src = "https://assets.dockwa.com/white-label-form/v1/dockwa-form.js"
    // script.async = true
    // document.body.appendChild(script)
    //   return () => {
    //     document.body.removeChild(script)
    //   }
  }, [])

  return (
    <Layout pageTitle="marina">
      <iframe
        src="https://dockwa.com/explore/destination/pwcyg5-dockside-marina-docksidebrewery/embed"
        id="dockwa-embed"
        frameborder="0"
        onload="dockwaOnload()"
        width="100%"
        height="1000px"
        style={{ opacity: 1 }}
      ></iframe>
    </Layout>
  )
}
